<template>
  <div>
    <filter-swapper  :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render :form.sync="contactForm" :fields="fieldsForm">
        </form-render>
      </template>
    </filter-swapper>
  </div>
</template>
<script>
export default {
  props: ['contact'],
  data() {
    return {
      contactForm: {},
      fieldsForm: [],
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      formUpdate: {}
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    contactForm: {
      handler(value) {
        this.updateForm(value)
      },
      deep: true
    },
    contact: {
      handler(value) {
        this.setFormData(value)
      },
      deep: true
    }
  },
  methods: {
    setInitialData() {
      this.fieldsForm = [
        {fieldType: 'FieldInput', name: 'carrier_manager_name', label: 'Escalamiento', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'carrier_manager_email', label: 'Correo Escalamiento', containerClass: 'col-12 col-md-4 container-info', type:'email'},
        {fieldType: 'FieldInput', name: 'hide', label: '', containerClass: 'col-12 col-md-4 collapse--space', disabled:'disabled'},
        {fieldType: 'FieldInput', name: 'ops_name', label: 'BO', placeholder: 'Ingrese Nombre BO', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'ops_email', label: 'Correo BO', containerClass: 'col-12 col-md-4 container-info', type:'email'},
        {fieldType: 'FieldInput', name: 'hide', label: '', containerClass: 'col-12 col-md-4 collapse--space', disabled:'disabled'},
        {fieldType: 'FieldInput', name: 'executive_bo_name', label: 'BO Ejecutivo', placeholder: 'Ingrese Nombre BO Ejecutivo', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'executive_bo_email', label: 'Correo BO Ejecutivo', containerClass: 'col-12 col-md-4 container-info', type:'email'},
        {fieldType: 'FieldInput', name: 'extra_info_bo', label: 'Adicionales', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'compensation_manager_name', label: 'Indemnización Ejecutivo', placeholder: 'Ingrese Nombre Indemnización Ejecutivo', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'compensation_manager_email', label: 'Correo Indemnización Ejecutivo', containerClass: 'col-12 col-md-4 container-info', type:'email'},
        {fieldType: 'FieldInput', name: 'extra_info_manager', label: 'Adicionales', containerClass: 'col-12 col-md-4 container-info'}
      ]
      this.setFormData(this.contact)
    },
    setFormData(data) {
      if (data) this.contactForm = {
        carrier_manager_name: data.carrier_manager.name ?? '',
        carrier_manager_email: data.carrier_manager.email ?? '',
        ops_name: data.ops.name ?? '',
        ops_email: data.ops.email ?? '',
        executive_bo_name: data.executive_bo.name ?? '',
        executive_bo_email: data.executive_bo.email ?? '',
        extra_info_bo: data.executive_bo.extra_info ?? '',
        compensation_manager_name: data.compensation_manager.name ?? '',
        compensation_manager_email: data.compensation_manager.email ?? '',
        extra_info_manager: data.compensation_manager.extra_info ?? ''
      }
    },
    updateForm(data) {
      this.formUpdate = {
        carrier: {
          name: data.carrier_manager_name ?? '',
          email: data.carrier_manager_email ?? ''
        },
        ops: {
          name: data.ops_name ?? '',
          email: data.ops_email ?? ''
        },
        executive_bo: {
          name: data.executive_bo_name ?? '',
          email: data.executive_bo_email ?? '',
          extra_info: data.extra_info_bo ?? ''
        },
        compensation_manager: {
          name: data.compensation_manager_name ?? '',
          email: data.compensation_manager_email ?? '',
          extra_info: data.extra_info_manager ?? ''
        }
      }
      this.$emit('formContact', this.formUpdate)
    }
  }
}
</script>
<style lang="scss">
</style>