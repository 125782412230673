<template>
  <b-card v-if="showDetail">
    <header-detail :detail="detail"></header-detail>
    <body-detail :detail="detail" :notVersion="'true'" @updateForm="goToDetail"></body-detail>
  </b-card>
</template>
<script>
import HeaderDetail from './HeaderDetail.vue'
import BodyDetail from './BodyDetail.vue'
import BaseServices from '@/store/services/index'
export default {
  name: 'detail-carrier',
  data() {
    return {
      idCarrier: null,
      detail: {},
      showDetail: false,
      baseService: new BaseServices(this)
    }
  },
  // props: ['detail'],
  components: {HeaderDetail, BodyDetail},
  mounted() {
    this.idCarrier = this.$route.params.id_carrier
    this.goToDetail(this.idCarrier)
  },
  methods: {
    goToDetail(id) {
      document.getElementById('loading-bg').style.display = 'block'
      this.showDetail = false
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : '',
        id_carrier: id
      }
      const queryParams = {}
      this.baseService.callService('getDetailCarrier', queryParams, params)
        .then(response => {
          this.detail = {...response.data[0]}
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
        .finally(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.showDetail = true
        })
    }
  }
}
</script>
